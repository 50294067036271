import React from "react";
import Footer from "../../components/footer";
import { useNavigate } from "react-router-dom";
const Header = React.lazy(() => import("../../components/header"));
const ismobile = window.innerWidth <= 768;

function PrivacyPolicy() {
  const navigate = useNavigate();
  return (
    <>
      <Header page={"terms"} />
      <div
        style={{
          padding: "2% 6%",
          width: "100%",
          maxWidth: "1400px",
          color: "#444",
          fontSize: "0.9em",
          margin: ismobile ? "15% auto auto auto" : "5% auto auto auto",
          scrollSnapAlign: "start",
          scrollMarginTop: "50px",
        }}
      >
        <section>
          <h2 style={{ textAlign: "center" }}>Privacy Policy for Dopamine</h2>
          <p>Effective date: 14th February, 2024</p>
          <p>
            Welcome to Dopamine!
            <br /> Dopamine is owned and operated by Vecino Fitness Private
            Limited ("Vecino Fitness," "we," "our," or "us"). This Privacy
            Policy explains how we collect, use, disclose, and protect your
            personal information when you access and use the Dopamine website,
            mobile application, and related services (collectively, the
            "Service"). By using the Service, you agree to the terms of this
            Privacy Policy.
          </p>
        </section>
        <section>
          <h3>1. Information We Collect</h3>
          <p>
            <b>a. Personal Information:</b> When you create an account or use
            certain features of the Service, we may collect personal information
            such as your name, email address, date of birth, and other
            identifying information.
            <br />
            <b>b. Fitness Data:</b> To provide fitness-related features, we may
            collect data about your workouts, exercise routines, and other
            fitness-related activities.
            <br />
            <b>c. Device Information:</b> We may collect information about the
            device you use to access the Service, including your device type,
            operating system, and mobile network information.
            <br />
            <b>d. Usage Information:</b> We may collect information about how
            you use the Service, such as your interactions with content, the
            pages you visit, and your activity on the platform.
            <br />
          </p>
        </section>
        <section>
          <h3>2. How We Use Your Information</h3>
          <p>
            <b>a. To Provide the Service:</b> We use your personal information
            to create and maintain your account, provide you with access to the
            Service, and deliver the features and functionalities you request.
            <br />
            <b>b. To Improve the Service:</b> We may use your information to
            analyze usage patterns, troubleshoot technical issues, and improve
            the performance and user experience of the Service.
            <br />
            <b>c. To Communicate with You:</b> We may send you service-related
            communications, such as account notifications and updates about the
            Service. d. To Personalize Your Experience: We may use your
            information to tailor the content, recommendations, and
            advertisements you see on the Service to match your preferences and
            interests.
            <br />
          </p>
        </section>
        <section>
          <h3>3. How We Disclose Your Information</h3>
          <p>
            <b>a. Service Providers:</b> We may share your information with
            third-party service providers who perform services on our behalf,
            such as data hosting, analytics, and customer support.
            <br />
            <b>b. Aggregated and Anonymized Data:</b> We may aggregate and
            anonymize your information to create statistical data that does not
            identify you personally. We may use and disclose such data for any
            purpose.
            <br />
            <b>c. Legal Requirements:</b> We may disclose your information if
            required to do so by law or in response to a valid legal request.
            <br />
          </p>
        </section>
        <section>
          <h3> 4. Your Choices and Controls</h3>
          <p>
            <b>a. Account Settings:</b> You can update your account information
            and preferences by accessing your account settings.
            <br />
            <b>b. Marketing Communications:</b> You can opt-out of receiving
            promotional emails from us by following the unsubscribe instructions
            included in the email.
            <br />
            <b>c. Device Permissions:</b>You can control the permissions granted
            to the Dopamine mobile application through your device settings.
            <br />
            <b>d. API Permissions:</b> Our app requires your location to track
            runs and walks accurately using GPS, even when running in the
            background. Your location data is used only for this purpose. By
            granting permission, you help us provide precise activity tracking.
            You can manage this permission in your device settings.
            <br />
          </p>
        </section>
        <section id="accountDeletion">
          <h3>5. Data Deletion Request Process</h3>
          <p>
            <b>1. Receipt of Deletion Request: </b> We receive and log your
            request for account deletion.
            <br />
            <b>2. Review by Customer & Compliance Team: </b> Our specialized
            team evaluates your deletion request for compliance and approval.
            <br />
            <b>3. Granting Approval: </b> Once reviewed, approval for the
            deletion process is issued.
            <br />
            <b>4. Retrieval Period: </b> A grace period of 30 days is provided
            for you to potentially retrieve your account.
            <br />
            <b>5. Final Account Erasure: </b> After the 30-day period, your
            account is permanently erased.
            <br />
            <b>6. New Account Creation Post-Deletion: </b> Should you choose to
            reactivate, a completely new account will need to be created, as the
            previous one cannot be recovered.
            <br />
            <br />
            <b>Data Impact: </b>Your profile, along with all uploaded photos,
            posts, videos, and any other content, will be irrevocably deleted.
            Recovery of any added content will not be possible.
            <br />
            <div
              style={{
                backgroundColor: "#eeeeee",
                padding: "16px",
                fontWeight: 500,
                borderRadius: 10,
                cursor: "pointer",
                marginTop: "16px",
              }}
            >
              Click here to{" "}
              <span
                onClick={() => navigate("/privacy/account-deletion")}
                style={{ color: "blue" }}
              >
                request account deletion.
              </span>
            </div>
          </p>
        </section>
        <section>
          <h3>6. Data Security</h3>
          <p>
            We take reasonable measures to protect your information from
            unauthorized access, use, or disclosure. However, no data
            transmission over the internet or electronic storage is entirely
            secure, and we cannot guarantee the absolute security of your
            information.
          </p>
        </section>
        <section>
          <h3>7. Children's Privacy</h3>
          <p>
            The Service is not intended for use by individuals under the age of
            14. We do not knowingly collect personal information from children
            under this age.
          </p>
        </section>
        <section>
          <h3>8. Changes to this Privacy Policy</h3>
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes to our data practices. We will notify you of any significant
            updates.
          </p>
        </section>
        <section>
          <h3>9. Contact Us</h3>
          <p>
            If you have any questions or concerns regarding these Terms, please
            contact us at:
          </p>
          <b>Email:</b>{" "}
          <a
            href="mailto:contact@dopamine.fit"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "#ff4a01" }}
            // className="footer-link"
          >
            contact@dopamine.fit
          </a>
          <p>
            <b> Address:</b>
            <br />
            Dopamine HQ
            <br />
            29 Floor Sunshine tower, Plot No 616, Tulsi Pipe Rd, Dadar West,
            Parel, Mumbai,
            <br /> Maharashtra 400013
          </p>
          <h3>9. Indemnification</h3>
          <p>
            You agree to indemnify and hold Dopamine, Vecino Fitness, and its
            affiliates, directors, officers, employees, and agents harmless from
            and against any claims, liabilities, damages, losses, and expenses,
            including reasonable attorney's fees, arising out of or in
            connection with your use of the Service or your violation of these
            Terms.
          </p>
        </section>
        <section>
          <h3>10. Changes to Terms</h3>
          <p>
            We reserve the right to modify or update these Terms at any time. We
            will provide notice of significant changes, but it is your
            responsibility to review these Terms periodically for updates.
          </p>
        </section>
        <section>
          <h3>11. Governing Law</h3>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of Maharashatra,India. Any dispute arising out of or
            relating to these Terms or the Service shall be subject to the
            exclusive jurisdiction of the courts of Maharashtra, India.
          </p>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at
          </p>
          <b>Email:</b>{" "}
          <a
            href="mailto:contact@dopamine.fit"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "#ff4a01" }}
            // className="footer-link"
          >
            contact@dopamine.fit
          </a>
          <p>
            <b> Address:</b>
            <br />
            Dopamine HQ
            <br />
            29 Floor Sunshine tower, Plot No 616, Tulsi Pipe Rd, Dadar West,
            Parel, Mumbai,
            <br /> Maharashtra 400013
            <br />
            <p>Thank you for using Dopamine!</p>
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default React.memo(PrivacyPolicy);
