import React from "react";
import { useNavigate } from "react-router-dom";

// import LOGOF from "../assets/images/logo.svg";
import LOGOF from "../assets/newLogoMain.svg";

import { ReactComponent as LOCATION } from "../assets/location.svg";
import appversion from "../../package.json";
const ismobile = window.innerWidth <= 768;

function Footer() {
  const navigate = useNavigate();
  const footerLinks = ismobile
    ? [
        <div onClick={() => navigate("/contact-us")} className="footer-link">
          Contact us
        </div>,
        <a
          href="https://wa.me/+919137534807"
          target="_blank"
          rel="noreferrer"
          className="footer-link"
        >
          +91-9137534807
        </a>,
        <a
          href="mailto:contact@dopamine.fit"
          target="_blank"
          rel="noreferrer"
          className="footer-link"
        >
          contact@dopamine.fit
        </a>,
        <div style={{ marginTop: "2em" }} className="footer-link">
          {" "}
        </div>,
        <div onClick={() => navigate("/about")} className="footer-link">
          About us
        </div>,
        <div onClick={() => navigate("/privacy")} className="footer-link">
          Privacy policy
        </div>,
        <div onClick={() => navigate("/terms")} className="footer-link">
          Terms & conditions
        </div>,
        <div onClick={() => navigate("/pricing")} className="footer-link">
          Pricing
        </div>,
        <div onClick={() => navigate("/refund-policy")} className="footer-link">
          Refund policy
        </div>,
      ]
    : [
        <div className="footer-link"> </div>,
        <div onClick={() => navigate("/contact-us")} className="footer-link">
          Contact us
        </div>,
        <div onClick={() => navigate("/about")} className="footer-link">
          About us
        </div>,
        <a
          href="https://wa.me/+919137534807"
          target="_blank"
          rel="noreferrer"
          className="footer-link"
        >
          +91-9137534807
        </a>,
        <div
          onClick={() => navigate("/privacy")}
          className="footer-link"
          style={{ cursor: "pointer" }}
        >
          Privacy policy
        </div>,
        <a
          href="mailto:contact@dopamine.fit"
          target="_blank"
          rel="noreferrer"
          className="footer-link"
        >
          contact@dopamine.fit
        </a>,
        <div
          onClick={() => navigate("/terms")}
          className="footer-link"
          style={{ cursor: "pointer" }}
        >
          Terms & conditions
        </div>,
        <div className="footer-link"> </div>,
        <div
          onClick={() => navigate("/pricing")}
          className="footer-link"
          style={{ cursor: "pointer" }}
        >
          Pricing
        </div>,
        <div className="footer-link"> </div>,
        <div
          onClick={() => navigate("/refund-policy")}
          className="footer-link"
          style={{ cursor: "pointer" }}
        >
          Refund policy
        </div>,
      ];

  return (
    <>
      <div className="footer-container">
        {/* <div className="footer-one" style={{ color: "#1C1C1C" }}>
          <div onClick={() => navigate("/")} className="footer-logo">
            <img
              loading="lazy"
              src={LOGOF}
              alt=""
              width="265px"
              height="63px"
            />
          </div>
          <div className="footer-location">
            <LOCATION className="footer-location-icon" />
            B/403, Shree Mangesh Apts Devidas Lane
            <br />
            Borivali West Mumbai 400103
          </div>
          <span>© 2022 Bwow Health and Fitness Private Limited</span>
        </div> */}
        <div
          className="footer-one"
          style={{ marginTop: "auto", marginBottom: 16 }}
        >
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="parent-company-title"
          >
            <LOCATION
              className="footer-location-icon"
              style={{ visibility: "hidden", marginBottom: 0 }}
            />
            <p style={{ marginBottom: 0, fontWeight: 600 }}> Company</p>
          </div>
          <div className="footer-location" style={{ marginTop: 0 }}>
            <LOCATION className="footer-location-icon" />
            Vecino Fitness Private Limited
            <br />
            Flr-11, B-1105, Plot No-211, Dalamal Tower,
            <br />
            Free Press Journal Marg, Nariman Point,
            <br />
            Mumbai, Maharashtra-400021
          </div>
        </div>
        {/* <div className="footer-center">
          <img
            loading="lazy"
            src={MSFORSTARTUP}
            alt="microsoft for startup"
            width={211}
            height={90}
          />
        </div> */}
        <div className="footer-two">
          <div
            style={{
              color: "#FFFFFF99",

              marginTop: ismobile ? "2em" : 0,
              fontWeight: 600,
            }}
            className="footer-link"
          >
            Reach out:
          </div>
          {footerLinks}
          {/* <div
          onClick={() => navigate("/terms")}
          className="footer-link"
          style={{ cursor: "pointer" }}
        >
          Terms & conditions
        </div> */}
        </div>
      </div>
      <div
        className="footer-container"
        style={{
          width: "100%",
          position: "relative",
          display: "block",
          padding: 0,
          margin: 0,
        }}
      >
        <p
          style={{
            color: "#ffffff99",
            textAlign: "center",
            margin: 0,
            padding: 0,
          }}
        >
          WebApp V-{appversion.version}{" "}
        </p>
      </div>
    </>
  );
}

export default React.memo(Footer);
