import React, { useEffect, useState } from "react";
import Rive from "@rive-app/react-canvas";
import html2pdf from "html2pdf.js";

import "./DownloadApp.css";
import logo from "../../assets/CC Logo.svg";
// import ANIM from "../../assets/Logo.mp4";
import Check from "../../assets/Check.svg";
import Pending from "../../assets/Pending.svg";
import Arrow from "../../assets/DownArrow.svg";
import WarrantyLock from "../../assets/WarrantLock.svg";
import Logo from "../../assets/dopamine_logo.svg";
import Filled from "../../assets/filled.svg";
import Tick from "../../assets/tick.svg";
import File from "../../assets/file_icon.svg";
import Bottom from "../../assets/bottom.svg";
import Exclamation from "../../assets/exclamation.svg";
import warrantyLogo from "../../assets/warranty.png";

import { Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { ActivationInfo } from "../ActivationInfoPage/ActivationInfo";

const DownloadApp = () => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const props = location?.state;
  const navigate = useNavigate();

  const date =
    props?.warranty_extended_to && new Date(props?.warranty_extended_to);
  const titleDate = date && format(date, "do MMMM yyyy");

  const productName = props?.productName;
  const cubecoins = props?.cubecoins;

  console.log("props", props);

  // const { created_at, expires_on } = props?.invoiceDetails;

  console.log("created_at, expires_on", props?.invoiceDetails?.product_name);

  const handleDownloadPDF = () => {
    const element = document.getElementById("pdf"); // Replace 'content' with the id of the element you want to convert to PDF
    html2pdf().from(element).save(`${props?.invoiceDetails?.product_name}.pdf`);
  };

  useEffect(() => {
    props?.is_redirect !== 1 && navigate("/");
    console.log(props);
  }, []);
  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  return (
    <>
      <div
        className="landing"
        id="landing"
        style={{ backgroundColor: "white", textAlign: "center" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            position: "relative",
            padding: "56px 15px 0px 15px",
          }}
        >
          <h1
            style={{
              fontFamily: "Poppins",
              fontSize: "36px",
              fontStyle: "italic",
              fontWeight: 700,
              lineHeight: "50.4px",
              letterSpacing: "-0.02em",
              textAlign: "left",
              display: "block",
              margin: "auto auto 16px auto",
            }}
          >
            Warranty
            <br />
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              Activated
              <img src={Tick} width="43px" height="43px" />
            </span>
          </h1>
          <p
            style={{
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "16.8px",
              textAlign: "center",
              margin: 0,
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            {productName}
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 6,
              padding: "4.35px 6.17px",
              borderRadius: "4.11px",
              border: "0.41px solid rgba(43, 43, 43, 1)",
              cursor: "pointer",
              margin: "10px auto 41px auto",
            }}
          >
            <img src={File} />

            <a
              onClick={() => {
                handleDownloadPDF();
              }}
            >
              {" "}
              <p
                style={{
                  color: "#0B0B0B",
                  fontFamily: "Poppins",
                  fontSize: "11.61px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "17.41px",
                  letterSpacing: "-0.04em",
                  margin: 0,
                }}
              >
                Download pdf
              </p>
            </a>
          </div>
          <div className="warranty-status" style={{ top: 290 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 4.08,
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>
                    <p
                      style={{
                        color: "#0B0B0B",
                        fontFamily: "Poppins",
                        fontSize: "16.667px",
                        fontStyle: "italic",
                        fontWeight: 700,
                        lineHeight: "normal",
                        letterSpacing: "-0.333px",
                      }}
                    >
                      {cubecoins
                        ? `Collect INR ${cubecoins * 10} Cashback*`
                        : ""}
                    </p>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        fontStyle: "italic",
                        fontWeight: 700,
                        lineHeight: "16px",
                        letterSpacing: "-0.04em",
                        textAlign: "left",
                        color: "rgba(28, 28, 28, 0.4)",
                      }}
                    >
                      Download cube club app.
                    </p>
                  </div>
                  <img src={Exclamation} />
                </div>
                <div
                  style={{
                    backgroundColor: "#FF7070",
                    color: "#FFFFFF",
                    padding: "2px 8px",
                    borderRadius: "4px",
                    color: "#FFF",
                    fontFamily: "Poppins",
                    fontSize: "12.5px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "140%",
                  }}
                >
                  Pending
                </div>
              </div>

              {/* <img src={Exclamation} /> */}
            </div>
            <div>
              <ul
                style={{
                  paddingInlineStart: 15,
                  color: "rgba(28, 28, 28, 0.4)",
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "160%", // Note: You can use string values for unit-based properties
                  letterSpacing: "-0.48px",
                }}
              >
                {/* <li>You have ₹1500 worth Dope Coins.</li> */}
                <li>Get cash back in cube coins.</li>
                <li>Get community access.</li>
                <li>Get expert led workouts.</li>
              </ul>
              <a
                href="https://cubeclub.page.link/open"
                target="_blank"
                rel="noreferrer"
              >
                <button
                  style={{
                    cursor: "pointer",
                    padding: "13px 0px",
                    width: "100%",
                    background:
                      "linear-gradient(240.74deg,#ffdf00 29.45%,#f4c430 94.15%)",
                    border: "none",
                    borderRadius: "8px",
                    color: "#0B0B0B",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "italic",
                    fontWeight: 700,
                    lineHeight: "normal", // or you can simply remove this line to use the default line height
                    letterSpacing: "-0.48px",
                  }}
                >
                  Get Access
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "none" }}>
        <ActivationInfo />
      </div>
    </>
  );
};

export { DownloadApp };
