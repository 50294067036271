// import logo from './logo.svg';
import "./App.css";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { ActivationInfo } from "./components/ActivationInfoPage/ActivationInfo";
import { Landing } from "./components/LandingPage/Landing";
import { OtpVerification } from "./components/OTP/OtpVerification";
import { DownloadApp } from "./components/DownloadApp/DownloadApp";
import { MainContextProvider } from "./helpers/context";
import Pricing from "./pages/others/Pricing";
import PrivacyPolicy from "./pages/others/PrivacyPolicy";
import RefundPolicy from "./pages/others/RefundPolicy";
import TermsAndConditions from "./pages/others/TermsAndConditions";
import Aboutus from "./pages/others/Aboutus";
import Contactus from "./pages/others/Contactus";
import AccountDeletion from "./pages/others/Deletion/AccountDeletion";

function App() {
  // return (
  //   <BrowserRouter>
  //   <Routes>

  //      {/* <Route path='/' exact component={<Landing />} /> */}
  //      <Route path='/' element={<Landing />} />
  //      <Route path='/activation' element={<ActivationInfo />} />
  //   </Routes>
  // </BrowserRouter>
  // )

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<Landing />} />
        <Route path="/activation" element={<ActivationInfo />} />
        <Route path="/OtpVerification" element={<OtpVerification />} />
        <Route path="/DownloadApp" element={<DownloadApp />} />
        <Route path="/ActivationInfo" element={<ActivationInfo/>}/>
        {/* <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/privacy/account-deletion" element={<AccountDeletion />} /> */}
      </Route>
    )
  );

  return (
    <MainContextProvider>
      <RouterProvider router={router} />
    </MainContextProvider>
  );

  // return (
  //   <div className="App">
  //     {/* <header className="App-header">
  //       <h1>CubeClub is Coming Soon</h1>
  //     </header> */}
  //     <Route path='/' exact component={<Landing />} />
  //     <Route path='/activation' exact component={<ActivationInfo />} />
  //     {/* <Landing /> */}
  //     {/* <ActivationInfo/> */}
  //   </div>
  // );
}

export default App;
