import { message } from "antd";
import axios from "axios";
import moment from "moment";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

// const REACT_APP_BASE_URL = "https://stag-api-cube.dopamine.fit";

const apiBaseUrl = `${REACT_APP_BASE_URL}/api/v1/`;

export const apiCall = axios.create({
  baseURL: apiBaseUrl,
  timeout: 15000,
  validateStatus: (status) => status < 500,
  transformRequest: [
    function (data, headers) {
      headers["Content-Type"] = "application/json";
      headers["Authorization"] = `Bearer ${
        fetchData("user_data")?.token || ""
      }`;
      return JSON.stringify(data);
    },
  ],
});

export const fetchData = (type) => {
  if (localStorage.getItem(type)) return JSON.parse(localStorage.getItem(type));
  return "";
};

export const storeData = (type, value) => {
  localStorage.setItem(type, JSON.stringify(value));
};

export async function getApi(
  payload = {},
  apiName = "getProductDetails",
  apiUrl = "cubeclub/",
  apiHeader = {}
) {
  let data = {};
  await axios
    .post(apiBaseUrl + apiUrl + apiName, payload, {
      headers: { ...apiHeader },
    })
    .then((res) => {
      if (res.status === 200 && res.data.success) {
        data = res.data;
      } else {
        errorMessage();
      }
    })
    .catch((err) => errorMessage("Please provide valid product details"));
  return data;
}

export async function saveApi(
  values = {},
  msg = "warranty activated",
  apiName = "extendWarranty",
  apiUrl = "cubeclub/",
  otp = false
) {
  let success = false;
  let response = {};
  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  await axios
    .post(apiBaseUrl + apiUrl + apiName, values, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((res) => {
      console.log(`res from warranty`, res);
      response = res;
      if (res.status === 200) {
        // if (res.data.success === true && otp) successMessage(msg);
        success = res.data.success;

        // return res.data.success
        // if (values.getId) {
        //   success = res.data.data;
        // } else {
        //   success = true;
        // }
      } else {
        errorMessage();
      }
    })
    .catch((err) => errorMessage());
  return response;
}

// export async function fetchApi(apiName = "getConfig", apiUrl = "ecomm/") {
//   let data = {};
//   const tokenString = localStorage.getItem("token");
//   if (tokenString) {
//     const userToken = JSON.parse(tokenString);
//     await axios
//       .get(apiBaseUrl + apiUrl + apiName, {
//         headers: { Authorization: `Bearer ${userToken}` },
//       })
//       .then((res) => {
//         if (res.status === 200 && res.data.success) {
//           data = res.data.data;
//         }  else {
//           errorMessage();
//         }
//       })
//       .catch((err) => (errorMessage(), console.log(err)));
//   }
//   return data;
// }

export function DateFormat(date, formatBy = "DD MMM YY h:mm A", is_add) {
  return is_add
    ? moment(date, "YYYY-MM-DD hh:mm").add("330", "minutes").format(formatBy)
    : (date, "YYYY-MM-DD hh:mm").format(formatBy);
}

export function successMessage(msg) {
  return message.success({
    content: msg + " Successfully.",
    style: { marginTop: "8vh" },
  });
}

export function errorMessage(msg = "Something went wrong, please try again.") {
  return message.error({
    content: msg,
    style: { marginTop: "8vh" },
  });
}

export function preloadImage(url = "../assets/logoText.png") {
  const newImage = new Image();
  newImage.src = url;
  return;
}
