import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ActivationInfo.css";
import moment from "moment";

import warrantyLogo from "../../assets/warranty.svg";
import { addYears, format } from "date-fns";
import Logo from "../../assets/cubeclub.svg";

const ActivationInfo = () => {
  const location = useLocation();
  const props = location?.state;

  const invoiceData = props?.invoiceDetails;

  const date =
    props?.warranty_extended_to && new Date(props?.warranty_extended_to);
  const titleDate = date && format(date, "do MMMM yyyy");
  const purchasedDate = date && addYears(date, 1);
  const formattedDate = purchasedDate && format(purchasedDate, "do MMMM yyyy");
  const navigate = useNavigate();
  console.log("warranty --->",props.invoiceDetails.warranty_tenure);
  useEffect(() => {
    props?.is_redirect !== 1 && navigate("/");
  }, []);
  return (
    <center className="container-main" id="pdf">
      <header className="landing-header-activation">
        <img
          src={Logo}
          alt="cube club"
          width="30%"
          style={{ marginTop: "50px" }}
        />
      </header>
      <div className="content-tag-activation">
        <img
          src={warrantyLogo}
          height="120px"
          style={{ padding: "54px 0 24px 0" }}
          alt="cube club"
        />
        <center className="main-title-activation">
          {titleDate ? (
            <p style={{ margin: 0 }}>
              Warranty Activated !{/* <br/> till {titleDate} */}
            </p>
          ) : (
            <p style={{ margin: 0 }}>Warranty activated </p>
          )}
        </center>
        <div className="sub-titile-activation">
          <p style={{ margin: 0 }}>
            We have sent you details <br /> on WhatsApp
            {/* We’ve sent you warranty details <br /> on your email & WhatsApp */}
          </p>
        </div>
        {/* <p style={{ margin: 0 }} className="product-details-tag">
          PRODUCT DETAILS
        </p> */}

        <div className="listing-activation">
          <p style={{ margin: 0 }}> Customer Name</p>
          {props?.customerDetails?.name && (
            <h5 style={{ margin: 0 }}>{props.customerDetails.name}</h5>
          )}
          <hr style={{ border: "1px solid white", margin: 0 }} />
          <p style={{ margin: 0 }}> Customer contact details</p>
          {props?.customerDetails?.phone && (
            <h5 style={{ margin: 0 }}>{props.customerDetails.phone}</h5>
          )}
          <hr style={{ border: "1px solid white", margin: 0 }} />
          <p style={{ margin: 0 }}> Product Details</p>
          <h5 style={{ margin: 0 }}>{props?.productName}</h5>
          <hr style={{ border: "1px solid white", margin: 0 }} />
          <p style={{ margin: 0 }}> Validity till</p>
          <h5 style={{margin:0}}>{moment(props.invoiceDetails.warranty_tenure ).format('LL')} </h5>
          {/* <h5 style={{ margin: 0,color:"black" }}>
            {props.invoiceDetails.warranty_tenure ||'1 year from Date of Purchase'  }-from date of purchase.
          </h5> */}
        </div>
        {/* <a
          href="google.com"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "flex-end",

              marginTop: 12,
              color: "var(--secondary-500-main, #2988F6)",
              fontFamily: "Poppins",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
            }}
          >
            Terms and Conditions
            <img src={Rigth_chev} />
          </span>
        </a> */}
      </div>
    </center>
  );
};

export { ActivationInfo };
