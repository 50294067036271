import React, { createContext, useEffect, useState } from "react";
// import { v4 as uuidv4 } from "uuid";
import { Alert, Snackbar } from "@mui/material";

import { storeData, fetchData, apiCall, preloadImage } from "./ApiFuctions";

const categoryArr = [
  { category_id: 1, category_name: "fitness" },
  { category_id: 2, category_name: "nutrition" },
  { category_id: 3, category_name: "yoga" },
  { category_id: 4, category_name: "Dance" },
  { category_id: 5, category_name: "Zumba" },
  { category_id: 6, category_name: "MMA" },
  { category_id: 7, category_name: "Boxing" },
  { category_id: 8, category_name: "Calisthenics" },
  { category_id: 9, category_name: "Pilates" },
  { category_id: 10, category_name: "Physio" },
  { category_id: 11, category_name: "Other" },
];

// const getIpAddress = () => {
//   apiCall
//     .get("https://geolocation-db.com/json/")
//     .then((res) => {
//       storeData("ip_address", res.data.IPv4);
//       return res.data.IPv4;
//     })
//     .catch((e) => console.log(e));
// };

const MainContext = createContext();

const MainContextProvider = ({ children }) => {
  // const uuid = fetchData("uuid") || uuidv4();
  // const ip_address = fetchData("ip_address") || getIpAddress();
  const [user, setUser] = useState(fetchData("user_data") || {});
  const [categories, setCategories] = React.useState(categoryArr);
  const [msg, setMsg] = useState("");

  useEffect(() => storeData("user_data", user), [user]);

  return (
    <MainContext.Provider value={{ user, setUser, setMsg, categories }}>
      {msg && (
        <Snackbar
          open
          autoHideDuration={2000}
          onClose={() => setMsg("")}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          message={msg}
        >
          <Alert
            severity={
              msg.includes("ucces")
                ? "success"
                : msg.includes("soon")
                ? "warning"
                : "error"
            }
          >
            {msg}
          </Alert>
        </Snackbar>
      )}
      {children}
    </MainContext.Provider>
  );
};

export { MainContext, MainContextProvider };
