import React from "react";
import { Menu } from "@mui/material";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import LOGOREVAMPED from "../assets/newLogoMain.svg";

// import FB from "../assets/facebook-icon.svg";
// import IG from "../assets/instagram-icon.svg";
// import TW from "../assets/twitter-icon.svg";
// import LI from "../assets/linkedin-icon.svg";
// import IGGREY from "../assets/insta-grey.svg";
// import LIGREY from "../assets/linkedin-grey.svg";

const socialLinks = [
  "https://www.instagram.com/movewithdopamine",
  "https://www.linkedin.com/company/dopamine-by-cube-club/mycompany/",
];

const pageLinks = ["expert", "brand", "centre"];

function Header({
  setRequestDrawer = () => {},
  createEventLogHandler = () => {},
  page = "not home",
}) {
  const location = useLocation();

  const [showMenu, setShowMenu] = React.useState(false);

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const ismobile = window.innerWidth <= 768;

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [searchParams] = useSearchParams();
  const campaign = searchParams.get("utm_campaign");

  return (
    <div
      // style={{}}
      className={
        page === "terms"
          ? "header-container-terms"
          : page === "home" && scrollPosition < 650 && campaign
          ? campaign === "community"
            ? "header-container header-container-black"
            : "header-container header-container-transparent"
          : page === "home" && scrollPosition < 650 && !campaign
          ? "header-container header-container-transparent"
          : "header-container"
      }
    >
      <Link to="/">
        <div className="header-logo">
          <img
            // src={page === "terms" || scrollPosition > 650 ? LOGOREVAMPED : LOGO}
            src={LOGOREVAMPED}
            alt=""
          />
        </div>
      </Link>
      <div className="header-links">
        {/* {pageLinks.map((link, i) => (
          <span key={i}>
            <Link
              to={`/${link}`}
              className={
                location.pathname.toLocaleLowerCase().includes(link)
                  ? "active"
                  : ""
              }
            >
              {link}
            </Link>
          </span>
        ))} */}
      </div>

      {/* <div className="header-social">
        {page === "home" && scrollPosition < 200
          ? [IGGREY, LIGREY].map((a, i) => (
              <a
                key={i}
                href={socialLinks[i]}
                rel="noreferrer"
                target="_blank"
                className="header-social-icon"
              >
                <img src={a} alt="" height="100%" width="100%" />
              </a>
            ))
          : [IG, LI].map((a, i) => (
              <a
                key={i}
                href={socialLinks[i]}
                rel="noreferrer"
                target="_blank"
                className="header-social-icon"
              >
                <img src={a} alt="" height="100%" width="100%" />
              </a>
            ))}
      </div> */}
      {/* <div className="header-menu">
        <IconButton
          aria-label="menu"
          id="menu-button"
          aria-controls={showMenu ? "show-menu" : undefined}
          aria-expanded={showMenu ? "true" : undefined}
          aria-haspopup="true"
          onClick={() => setShowMenu(!showMenu)}
        >
          <MENU className="header-menu-icon" />
        </IconButton>
      </div> */}
      {!ismobile && (
        <Menu
          id="show-menu"
          MenuListProps={{ "aria-labelledby": "menu-button" }}
          anchorEl={
            showMenu ? document.getElementById("menu-button") : undefined
          }
          open={showMenu}
          onClose={() => setShowMenu(false)}
        >
          <div>
            <ul className="header-links-mobile">
              {pageLinks.map((link, i) => (
                <li key={i}>
                  <Link
                    to={`/${link}`}
                    className={
                      location.pathname.toLocaleLowerCase().includes(link)
                        ? "active"
                        : ""
                    }
                  >
                    {link}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {/* <div>
            {[FB, IG, TW, LI].map((a, i) => (
              <a
                key={i}
                href={socialLinks[i]}
                rel="noreferrer"
                target="_blank"
                className="header-social-icon"
              >
                <img src={a} alt="" height={30} width={30} />
              </a>
            ))}
          </div> */}
        </Menu>
      )}
    </div>
  );
}

export default React.memo(Header);
