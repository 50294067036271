import "./Landing.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import OnboardingModal from "../../components/OnboardingModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { logEvent } from "firebase/analytics";

import WHATSAPP from "../../assets/whatsAppIcon.svg";
import COIN from "../../assets/cubeCoin.svg";
import COINMOB from "../../assets/coinForMob.svg";
import COINMOBSMALL from "../../assets/coinMob.svg";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import { MainContext } from "../../helpers/context";
import OTPInput from "../../components/OtpInput";
import RightChev from "../../assets/menuFilled.svg";
import LeftChev from "../../assets/menuUnfilled.svg";
import Apple from "../../assets/App Store PNG.png";
import Android from "../../assets/Play Store PNG.png";
import Info from "../../assets/info.svg";
import { analytics } from "../../components/FireBase";
import { apiCall } from "../../helpers/ApiFuctions";
import Header from "../header";
import Footer from "../footer";

export default function LandingMain() {
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [dopamineScore, setDopamineScore] = useState("");
  const [selectedInterests, setSelectedInterest] = useState([]);
  const [isDisabled, setIsDisabled] = useState({
    onboarding_step: 0,
    flag: false,
  });

  console.log("userDetails", userDetails);
  const [searchParams] = useSearchParams();

  const type = searchParams.get("utm_type");

  const popupRef = useRef(null);
  const ismobile = window.innerWidth <= 768;

  const genders = ["Female", "Male", "Other", "Prefer not to say"];
  const workoutDays = [
    "1 day a week",
    "2 days a week",
    "3 days a week",
    "4 days a week",
    "5 days a week",
    "6 days a week",
    "7 days a week",
  ];

  const consistentDays = [
    "1 day ",
    "2 days",
    "3 days",
    "4 days",
    "5 days",
    "6 days",
    "7 days",
  ];

  const scoreHandler = () => {
    setOpenModal(true);
    logEvent(analytics, "check_score_cta", {
      source: type || "no source",
    });
  };

  const { setMsg, setUser, user, categories } = useContext(MainContext);

  const navigate = useNavigate();

  const submitHandler = (e) => {
    logEvent(analytics, "send_otp_btn_web", { source: type });
    sendOtphandler();
  };

  useEffect(() => {
    setUserDetails({ ...userDetails, interests: selectedInterests });
  }, [selectedInterests]);

  const verifyOtpHandler = () => {
    logEvent(analytics, "verify_otp_btn_web", { source: type });
    setLoading(true);
    let payload = {
      otp: formData.otp,
      mobile: formData.number,
      login_type: "mobile",
      login_source: "website",
    };
    apiCall
      .post("user/verifyOtp", payload)
      .then((res) => {
        if (res.data.success) {
          setUser(res.data.data);
          setUserDetails((prev) => ({
            ...prev,
            interests: res.data.data?.interests,
            initial_coins: res.data.data?.total_coins - 100,
          }));
          // createCustomer(res.data.data.user_id);
          res?.data?.data?.onboarding_step !== 0 &&
            setIsDisabled((prev) => ({
              ...prev,
              flag: true,
              onboarding_step: res?.data?.data?.onboarding_step,
            }));

          res.data.data.onboarding_step === 4 &&
            setDopamineScore(res.data.data.dopescore);
          res.data.data.onboarding_step === 0
            ? setStep(step + 1)
            : res.data.data.onboarding_step === 5
            ? setStep(5)
            : setStep(res?.data?.data.onboarding_step + 1);
        } else setMsg(res.data.msg);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const createCustomer = (user_id) => {
    setLoading(true);
    let payload = { user_id, api_name: "createCustomer", is_web_onboarded: 1 };
    apiCall
      .post("v1/user/createCustomer", payload)
      .then((res) => {
        if (res.status === 200 && res.data.success) {
          res?.data?.data?.onboarding_step !== 0 &&
            setIsDisabled((prev) => ({
              ...prev,
              flag: true,
              onboarding_step: res?.data?.data?.onboarding_step,
            }));

          res.data.data.onboarding_step === 4 &&
            setDopamineScore(res.data.data.dopescore);
          res.data.data.onboarding_step === 0
            ? setStep(step + 1)
            : res.data.data.onboarding_step === 5
            ? setStep(5)
            : setStep(res?.data?.data.onboarding_step + 1);
          setLoading(false);
        } else setMsg(res.data.msg);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const editProfile = () => {
    setLoading(true);
    let payload = {
      ...userDetails,

      user_id: user.user_id,
      api_name: "editProfile",
      onboarding_step: step,

      type: "customer",
    };
    apiCall
      .post("user/editProfile", payload)
      .then((res) => {
        if (res.status === 200 && res.data.success) {
          step === content?.length ? setStep(step) : setStep(step + 1);

          setLoading(false);
          res.data.data && setDopamineScore(res.data.data);
        } else setMsg(res.data.msg);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const content = {
    0: (
      <div className="content">
        <p className="content-title" style={{ marginBottom: 9 }}>
          About You
        </p>
        <p className="small-description">
          Enter your{" "}
          <span>
            {" "}
            <img
              alt="whatsAppIcon"
              className="whatsAppIcon"
              src={WHATSAPP}
            />{" "}
          </span>{" "}
          WhatsApp number to register
        </p>
        <div className="form-container">
          <FormControl className="form-container-1" variant="standard">
            <Input
              className="input-name"
              style={{ borderBottom: "none" }}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder="Enter your name"
              size="large"
              id="component-simple"
            />
            <div className="gradient-bg"></div>
          </FormControl>
          <FormControl className="form-container-1" variant="standard">
            <Input
              type="number"
              pattern="/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
                e.target.setCustomValidity("");
              }}
              onChange={(e) =>
                setFormData({ ...formData, number: e.target.value })
              }
              placeholder="Enter your number"
              size="large"
              id="component-simple"
            />
            <div className="gradient-bg"></div>
          </FormControl>
          <Button
            disabled={loading || formData?.number?.length < 10}
            onClick={() => {
              submitHandler();
            }}
            className="otp-button"
            style={{ marginTop: 42 }}
          >
            Get OTP
          </Button>
          {/* <Button disabled={loading} onClick={submitHandler} className="email-button" style={{marginTop:22}}>Use Email Instead</Button> */}

          <p onClick={() => navigate("/terms")} className="term-condition">
            Terms and condition and Privacy policy
          </p>
        </div>
      </div>
    ),
    1: (
      <div className="content" id="otp">
        <p className="content-title">Enter OTP</p>
        <p className="small-description">
          {`Please enter OTP sent to +91 ${formData?.number} on `}
          <span>
            {" "}
            <img
              alt="whatsAppIcon"
              className="whatsAppIcon"
              src={WHATSAPP}
            />{" "}
          </span>{" "}
          WhatsApp
        </p>
        <div className="form-container">
          <OTPInput onChangeOTP={(v) => setFormData({ ...formData, otp: v })} />
          <Button
            disabled={loading}
            onClick={verifyOtpHandler}
            className="otp-button"
            style={{ marginTop: 42 }}
          >
            Verify
          </Button>

          <p onClick={() => navigate("/terms")} className="term-condition">
            Terms and condition and Privacy policy
          </p>
        </div>
      </div>
    ),
    2: (
      <div className="content">
        <p className="content-title">Health Details</p>
        <div className="bar-container">
          <div className={`bar filled`}></div>
          <div className={`bar unfilled`}></div>
          <div className={`bar unfilled`}></div>
        </div>

        {/* Bars filled will come */}
        <p className="health-desc" style={{ marginTop: ismobile ? 10 : 32 }}>
          Let's understand your physical self
        </p>

        <div
          className="health"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: 34,
            marginTop: 46,
          }}
        >
          <FormControl className="form-container-2" fullWidth>
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              placeholder="Gender"
              id="demo-simple-select"
              value={userDetails.gender}
              label="Select Gender"
              onChange={(e) =>
                setUserDetails({ ...userDetails, gender: e.target.value })
              }
            >
              {genders.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="form-container-2" fullWidth>
            <TextField
              id="outlined-number"
              label="Age"
              type="number"
              placeholder="Enter your age"
              style={{ background: "transparent" }}
              onChange={(e) =>
                setUserDetails((prev) => ({ ...prev, age: e.target.value }))
              }
              inputProps={{
                min: 1,
                max: 99,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 34,
            marginTop: 39,
          }}
        >
          <img
            style={{
              display:
                isDisabled?.flag && isDisabled?.onboarding_step + 1 === step
                  ? "none"
                  : "block",
              cursor: "pointer",
            }}
            alt="left_chev"
            src={LeftChev}
            onClick={() => {
              logEvent(analytics, "physical_stats_btn_web_prev", {
                source: type,
              });
              setStep(1);
              setStep(0);
              setUserDetails({});
            }}
          />

          <img
            style={{
              display: "block",
              cursor: "pointer",
              cursor:
                (Object.values(userDetails)[0] &&
                  Object.values(userDetails)[1]) ||
                !loading
                  ? "pointer"
                  : "not-allowed",
            }}
            alt="right_chev"
            src={RightChev}
            onClick={() => {
              logEvent(analytics, "physical_stats_btn_web_next", {
                source: type,
              });
              editProfile({});
            }}
          />
        </div>
        <p onClick={() => navigate("/terms")} className="term-condition">
          Terms and condition and Privacy policy
        </p>
      </div>
    ),
    3: (
      <div className="content">
        <p className="content-title">Health Details</p>
        <div className="bar-container">
          <div className={`bar filled`}></div>
          <div className={`bar filled`}></div>
          <div className={`bar unfilled`}></div>
        </div>

        {/* Bars filled will come */}
        <p className="health-desc" style={{ marginTop: ismobile ? 10 : 32 }}>
          How do you stay active?
        </p>

        <div
          className="health"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 15,
            marginTop: 15,
          }}
        >
          {categories?.map(({ category_name, category_id }, i) => (
            <div
              className={
                selectedInterests.includes(category_id)
                  ? "category-container-selected"
                  : "category-container"
              }
              key={category_id}
              onClick={() => {
                const isSelected = selectedInterests.includes(category_id);
                if (isSelected) {
                  setSelectedInterest((prev) =>
                    prev.filter((id) => id !== category_id)
                  );
                } else {
                  setSelectedInterest((prev) => [...prev, category_id]);
                }
              }}
            >
              <p>{category_name}</p>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 34,
            marginTop: 39,
          }}
        >
          <img
            style={{
              display:
                isDisabled?.flag && isDisabled?.onboarding_step + 1 === step
                  ? "none"
                  : "block",
              cursor: "pointer",
            }}
            alt="left_chev"
            src={LeftChev}
            onClick={() => {
              logEvent(analytics, "categories_selection_web_prev", {
                source: type,
              });
              setStep(step - 1);
            }}
          />

          <img
            style={{
              display: "block",
              cursor: "pointer",
            }}
            onClick={() => {
              editProfile({});
              logEvent(analytics, "categories_selection_web_next", {
                source: type,
              });
            }}
            alt="right_chev"
            src={RightChev}
          />
        </div>
        <p onClick={() => navigate("/terms")} className="term-condition">
          Terms and condition and Privacy policy
        </p>
      </div>
    ),
    4: (
      <div className="content">
        <p className="content-title">Health Details</p>
        <div className="bar-container">
          <div className={`bar filled`}></div>
          <div className={`bar filled`}></div>
          <div className={`bar filled`}></div>
        </div>

        {/* Bars filled will come */}
        <p className="health-desc" style={{ marginTop: ismobile ? 10 : 32 }}>
          How many days have you worked out in last 7 days?
        </p>

        <div
          className="health-activity"
          style={{
            width: "100%",

            marginTop: 46,
          }}
        >
          <FormControl
            className="form-container-4"
            fullWidth
            style={{
              marginBottom: 24,
            }}
          >
            <InputLabel id="demo-simple-select-label">
              Current workout frequency?
            </InputLabel>
            <Select
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={userDetails.total_coins}
              onChange={(e) =>
                setUserDetails({
                  ...userDetails,
                  initial_coins:
                    userDetails.initial_coins + e.target.value * 10,
                })
              }
            >
              {consistentDays.map((data, i) => (
                <MenuItem key={data} value={i + 1}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 16,
            marginTop: 42,
          }}
        >
          <img
            style={{
              display:
                isDisabled?.flag && isDisabled?.onboarding_step + 1 === step
                  ? "none"
                  : "block",
              cursor: "pointer",
            }}
            alt="left"
            src={LeftChev}
            onClick={() => {
              logEvent(analytics, "get_score_web_prev", {
                source: type,
              });
              setStep(step - 1);
            }}
          />
          <Button
            disabled={loading}
            onClick={() => {
              editProfile();
              logEvent(analytics, "get_score_web_next", {
                source: type,
              });
            }}
            className="otp-button"
          >
            Get Coins
          </Button>
        </div>
        <p onClick={() => navigate("/terms")} className="term-condition">
          Terms and condition and Privacy policy
        </p>
      </div>
    ),
    5: (
      <div className="content">
        <div style={{ overflowY: "scroll" }}>
          {!ismobile && (
            <p
              style={{
                color: "rgba(11, 11, 11, 0.50)",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "140%",
                letterSpacing: "-0.4px",
                textAlign: "center",
              }}
            >
              Based on your inputs, you earned
            </p>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 12,
            }}
          >
            {ismobile ? (
              <p className="content-title-last ">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "22px",
                    letterSpacing: "-0.04em",
                    textAlign: "center",
                    fontStyle: "normal",
                  }}
                >
                  {" "}
                  Here's Your
                </span>
                <br />
                Cash back
              </p>
            ) : (
              <p className="content-title-last">Cube Coins</p>
            )}
            {!ismobile && (
              <Tooltip
                title={
                  <div>
                    <p
                      style={{
                        fontFamily: "Outfit",
                        fontSize: "20px",
                        fontWeight: 600,
                        lineHeight: "28px",
                        letterSpacing: "-0.02em",
                        color: "#0B0B0B",
                      }}
                    >
                      How to redeem your coins?
                    </p>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: 500,
                        lineHeight: "16.8px",
                        letterSpacing: "0px",
                      }}
                    >
                      Redeem your cube coins on your next purchase of cube
                      products.
                    </p>
                  </div>
                }
                placement="top-start"
                arrow
              >
                <span
                  style={{
                    backgroundColor: "#FFFFFF",
                    zIndex: "1000",
                    marginTop: 5,
                  }}
                >
                  <img src={Info} />
                </span>
              </Tooltip>
            )}
          </div>

          {ismobile ? (
            <div className="score-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid rgba(247, 245, 242, 1)",
                  borderRadius: "28px",
                  marginTop: "17px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 12px",
                    gap: 8,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "17px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "rgba(128, 127, 125, 1)",
                    }}
                  >
                    Cube Coins
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 4,
                    }}
                  >
                    <img src={COINMOBSMALL} />
                    <p className="dope-score">
                      {userDetails?.initial_coins + 100 || "0"}
                    </p>
                  </div>
                  <p
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "17px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "rgba(204, 204, 201, 1)",
                    }}
                  >{`worth of ₹ ${
                    (userDetails?.initial_coins + 100) * 10 || 0
                  }`}</p>
                </div>
                <img src={COINMOB} />
              </div>

              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "22px",
                  letterSpacing: "-0.04em",
                  textAlign: "left",
                  color: "rgba(11, 11, 11, 1)",
                  marginTop: 28,
                }}
              >
                How to redeem your coins ?
              </p>
              <p
                style={{
                  marginTop: 12,
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "17px",
                  letterSpacing: "0px",
                  textAlign: "left",
                  color: "rgba(11, 11, 11, 0.5)",
                }}
              >
                Redeem your cube coins on your next purchase of cube products.
              </p>
            </div>
          ) : (
            <div className="score-container ">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 8,
                }}
              >
                <img src={COIN} width="67px" />
                <p className="dope-score">
                  {userDetails?.initial_coins + 100 || "0"}
                </p>
              </div>
              <p
                style={{
                  color: "rgba(11, 11, 11, 0.5)",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  letterSpacing: "0px",
                }}
              >
                Redeem your cube coins on your next purchase of cube products.
              </p>
            </div>
          )}
        </div>
        <div>
          {ismobile ? (
            <a
              href="https://onelink.to/5z9d3p"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
              }}
              onClick={() =>
                logEvent(analytics, "download_app_mobile", {
                  source: type,
                })
              }
            >
              <Button className="download-btn">Download App</Button>
            </a>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                marginTop: 39,
              }}
            >
              <a
                href="https://apps.apple.com/in/app/dopamine-lets-move-daily/id1618767813"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                }}
                onClick={() => {
                  logEvent(analytics, "iOS_app_download_web", {
                    source: type,
                  });
                }}
              >
                <img
                  style={{
                    display: "block",
                    cursor: "pointer",
                  }}
                  width={160}
                  alt="apple"
                  src={Apple}
                />
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=fit.dopamine.business"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                }}
                onClick={() => {
                  logEvent(analytics, "android_app_download_web", {
                    source: type,
                  });
                }}
              >
                {" "}
                <img
                  style={{
                    display: "block",
                    cursor: "pointer",
                  }}
                  width={160}
                  alt="android"
                  src={Android}
                />
              </a>
            </div>
          )}
          <p onClick={() => navigate("/terms")} className="term-condition">
            Terms and condition and Privacy policy
          </p>
        </div>
      </div>
    ),
  };

  const nextBlockRefs = useRef([]);

  const scrollToNextBlock = (index) => {
    const totalBlocks = nextBlockRefs.current.length;

    const nextIndex = (index + 1) % totalBlocks;

    const nextBlock = nextBlockRefs.current[nextIndex];
    if (nextBlock) {
      nextBlock.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sendOtphandler = () => {
    setLoading(true);
    let payload = {
      mobile: formData.number,
      name: formData.name,
      login_type: "mobile",
    };
    // if (data.invited_by) payload = { ...payload, invited_by: data.invited_by };
    apiCall
      .post("user/sendOtp", payload)
      .then((res) => {
        if (res.status === 200 && res.data.success) {
          setMsg("Otp sent successfully");
          setStep(step + 1);
        } else setMsg(res.data.msg);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const screens = (
    // type === "community" ? (
    <>
      <div
        className="landing-screen-3"
        ref={(el) => (nextBlockRefs.current[0] = el)}
      >
        <div className="center">
          {ismobile ? (
            <h1>
              Join the Community <br /> that rewards you to <br />
              <span>Move.</span>
              <p className="description-3" style={{ fontWeight: 500 }}>
                Get rewarded for every workout
              </p>
            </h1>
          ) : (
            <h1>
              Join the Community that rewards you to
              <br />
              <span>Move</span>
              {
                <p className="description-3" style={{ fontWeight: 500 }}>
                  Get rewarded for every workout
                </p>
              }
            </h1>
          )}

          <button
            onClick={scoreHandler}
            className="cta-button-landing-3"
            style={{ borderRadius: 4 }}
          >
            Get Access
          </button>
        </div>
      </div>
    </>
  );
  // ) : type === "challenge" ? (
  //   <>
  //     <div
  //       className="landing-screen-2"
  //       ref={(el) => (nextBlockRefs.current[1] = el)}
  //     >
  //       <div className="center-right">
  //         <h1>
  //           Challenge your
  //           <br />
  //           <span>Friends</span>
  //           {ismobile ? (
  //             <p className="description-2">
  //               Add friends to your group and
  //               <br /> record your progress together .
  //             </p>
  //           ) : (
  //             <p className="description-2">
  //               Add friends to your <span>group </span>
  //               <br /> and <span>record your progress together</span>
  //             </p>
  //           )}
  //         </h1>

  //         <button onClick={scoreHandler} className="cta-button-landing-2">
  //           Check Score
  //         </button>
  //       </div>
  //     </div>
  //   </>
  // ) : type === "score" ? (
  //   <>
  //     <div
  //       className="landing-screen-3"
  //       ref={(el) => (nextBlockRefs.current[2] = el)}
  //     >
  //       <div className="center">
  //         <h1>
  //           Build your
  //           <br />
  //           <span>Dopamine</span>
  //           {ismobile ? (
  //             <p className="description-3">
  //               Get a Dopamine Score to build
  //               <br /> your consistency.
  //             </p>
  //           ) : (
  //             <p className="description-3">
  //               Get a <span>Dopamine Score</span> to build
  //               <span>your consistency.</span>
  //             </p>
  //           )}
  //         </h1>

  //         <button onClick={scoreHandler} className="cta-button-landing-3">
  //           Check Score
  //         </button>
  //       </div>
  //     </div>
  //   </>
  // ) : (
  //   <>
  //     <div
  //       className="landing-screen-3"
  //       ref={(el) => (nextBlockRefs.current[2] = el)}
  //     >
  //       <div className="center">
  //         <h1>
  //           Build your
  //           <br />
  //           <span>Dopamine</span>
  //           {ismobile ? (
  //             <p className="description-3">
  //               Get a Dopamine Score to build
  //               <br /> your consistency.
  //             </p>
  //           ) : (
  //             <p className="description-3">
  //               Get a <span>Dopamine Score</span> to build
  //               <span>your consistency.</span>
  //             </p>
  //           )}
  //         </h1>

  //         <button onClick={scoreHandler} className="cta-button-landing-3">
  //           Check Score
  //         </button>
  //       </div>
  //     </div>

  //     <div
  //       className="landing-screen-2"
  //       ref={(el) => (nextBlockRefs.current[1] = el)}
  //     >
  //       <div className="center-right">
  //         <h1>
  //           Challenge your
  //           <br />
  //           <span>Friends</span>
  //           {ismobile ? (
  //             <p className="description-2">
  //               Add friends to your group and
  //               <br /> record your progress together .
  //             </p>
  //           ) : (
  //             <p className="description-2">
  //               Add friends to your <span>group </span>
  //               <br /> and <span>record your progress together</span>
  //             </p>
  //           )}
  //         </h1>

  //         <button onClick={scoreHandler} className="cta-button-landing-2">
  //           Check Score
  //         </button>
  //       </div>
  //     </div>

  //     <div
  //       className="landing-screen-1"
  //       ref={(el) => (nextBlockRefs.current[0] = el)}
  //     >
  //       <div className="center-left">
  //         <h1>
  //           Grow with a
  //           <br />
  //           <span>Community</span>
  //           {ismobile ? (
  //             <p style={{ fontWeight: 500 }} className="description-1">
  //               Join like minded communities <br />
  //               and stay accountable.
  //             </p>
  //           ) : (
  //             <p className="description-1" style={{ fontWeight: 500 }}>
  //               Join <span className="sub-desc">like minded communities</span>
  //               <br />
  //               and stay
  //               <span className="sub-desc">accountable.</span>
  //             </p>
  //           )}
  //         </h1>

  //         <button onClick={scoreHandler} className="cta-button-landing-1">
  //           Check Score
  //         </button>
  //       </div>
  //     </div>
  //   </>
  // );

  return (
    <>
      <Header />
      <div className="landing-container">
        {screens}

        <OnboardingModal
          setFormData={setFormData}
          setUserDetails={setUserDetails}
          setSelectedInterest={setSelectedInterest}
          openModal={openModal}
          setOpenModal={setOpenModal}
          step={step}
          content={content}
          setStep={setStep}
          setDopamineScore={setDopamineScore}
        />
      </div>
      <Footer />
    </>
  );
}
