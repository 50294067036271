import React, { useEffect, useState } from "react";

import "./Landing.css";
import logo from "../../assets/CubeLogo.svg";
import WhatsAppIcon from "../../assets/WhatsApp.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { getApi, saveApi, successMessage } from "../../helpers/ApiFuctions";
import { message, Button, Dropdown, Badge } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ClockWise from "../LandingPage/ClockClockwise.svg";
import moment from "moment";
import LandingMain from "./LandingMain";
import Header from "../header";

const Landing = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [prodData, setProdData] = useState();
  const [ProductID, setProductID] = useState(null);
  const [ProductEAN, setProductEAN] = useState(null);
  const [loading, setLoading] = useState(false);
  const [productError, setProductError] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [orderedFrom, setOrderedFrom] = useState("");

  const getProductDetails = async (payload = {}) => {
    // setMainLoader(true);
    const searchParams = new URLSearchParams(location.search);
    setProductID(searchParams.get("ProductID"));
    setProductEAN(searchParams.get("ProductEAN"));
    // setWarrantyDate(searchParams.get("WarrantyDate"));
    payload =
      searchParams.get("ProductID") != null
        ? {
            ProductID: searchParams.get("ProductID"),
          }
        : {
            ProductEAN: searchParams.get("ProductEAN"),
          };
    // if (searchParams.get("ProductID") != null && searchParams.get("WarrantyDate")) {
    {
      const res = await getApi(payload);
      if (!res?.data?.product_name) {
        setProductError(true);
        // message.warning({content:"Please provide valid product details"})
      }
      if (res?.data) {
        setProdData(res.data);
      }
    }
    setMainLoader(false);
  };
  useEffect(() => {
    getProductDetails();
  }, [location]);

  console.log("ProductEAN", ProductEAN);

  const formHandler = async (e, payload = {}) => {
    e.preventDefault();
    setLoading(true);

    const name = e.target?.name?.value;
    const phone = e.target?.phone?.value;
    const store_name = e.target?.storeName?.value;
    const ean_code = ProductEAN;
    const sku_code = ProductID;
    const channel = orderedFrom;
    // const email = e.target?.email?.value;
    // const warranty_extended_to = warrantyDate;
    // const ordered_from = orderedFrom;

    const res = await saveApi(
      (payload = ean_code
        ? {
            name,
            phone,
            ean_code,
            is_add: 1,
            channel,
            store_name,
          }
        : {
            name,
            phone,
            sku_code,
            is_add: 1,
            channel,
            store_name,
          }),
      "OTP sent",
      "cubeProductsWarranty"
    );

    console.log("res", res);
    if (res?.data.success) {

      const id = res.data.data[0]?.id;
      const warranty_tenure = res.data.data[0]?.warranty_tenure;
      console.log("res", res);
      setLoading(false);
      // navigate("/OTPVerification", {
      //   state: {
      //     phone,
      //     id,
      //     name,
      //     ean_code,
      //     sku_code,
      //     channel,
      //     is_redirect: 1,
      //     productName: prodData?.product_name,
      //     cubecoins: prodData?.dopecoins,
      //   },
      // });

      navigate("/ActivationInfo", {
        state: {
          is_redirect: 1,
          invoiceDetails:{product_name: prodData?.product_name,warranty_tenure:warranty_tenure},
          customerDetails: {name:name,phone:phone},
          productName: prodData?.product_name,
          cubecoins: prodData?.dopecoins,
        },
      })

      // ean_code
      //   ? navigate("/OTPVeri f ication", { state: { phone, id, name, ean_code } })
      //   : navigate("/Landing");
      successMessage("Warranty Registered ");
    } else {
      message.warning({ content: "Something went wrong!" });
      setLoading(false);
    }
    // }
  };

  return (
    <>
      {/* {mainLoader && (
        <div className="logo-div">
          <div className="logo-error">
            <LoadingOutlined
              style={{
                fontSize: 36,
              }}
              spin
            />
          </div>
        </div>
      )} */}
      {!mainLoader && productError && (
        <>
          <LandingMain />
        </>
      )}

      {!mainLoader && !productError && (ProductID || ProductEAN) ? (
        <center className="landing">
          {/* <header className="landing-header">
            <img src={logo} alt="cube club" />
          </header> */}

          <center className="content-tag">
            <div className="main-title">
              <p style={{ margin: 0 }}>
                Activate
                <br /> Warranty
              </p>
            </div>

            <div className="sub-titile">
              <p style={{ margin: 0 }}>
                Activate 1 year warranty on your{" "}
                <b>
                  {" "}
                  {prodData?.product_name
                    ? prodData?.product_name
                    : "Dopamine Product"}
                </b>
              </p>
            </div>
            <div
              className="form-container-warranty"
              style={{ position: "relative" }}
            >
              <p className="small-description">
                Enter your Name and
                <br />
                <span style={{ margin: "0px 2px" }}>
                  {" "}
                  <img
                    alt="whatsAppIcon"
                    className="whatsAppIcon"
                    src={WhatsAppIcon}
                    width="16px"
                    style={{ display: "block", margin: "auto" }}
                  />{" "}
                </span>{" "}
                WhatsApp number to register
              </p>
              <form
                onSubmit={(e) => {
                  return formHandler(e);
                }}
              >
                <div
                  style={{
                    margin: "32px 12px",
                    background:
                      "linear-gradient(90deg, rgba(11, 11, 11, 0) 0%,rgba(11, 11, 11, 0.2) 50%,rgba(11, 11, 11, 0) 100%)",
                  }}
                >
                  <input
                    placeholder="Enter your name"
                    type="text"
                    name="name"
                    required={true}
                    style={{
                      marginBottom: 1,
                      border: "none",
                      backgroundColor: "white",
                      opacity: 1,
                      textAlign: "center",
                    }}
                  />
                </div>

                <div
                  style={{
                    margin: "32px 12px",
                    background:
                      "linear-gradient(90deg, rgba(11, 11, 11, 0) 0%,rgba(11, 11, 11, 0.2) 50%,rgba(11, 11, 11, 0) 100%)",
                  }}
                >
                  <input
                    placeholder="Enter Your Number"
                    type="number"
                    pattern="/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/"
                    name="phone"
                    required={true}
                    min={1000000000}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                      e.target.setCustomValidity("");
                    }}
                    onInvalid={(e) => {
                      e.target.setCustomValidity(
                        "Please enter a valid 10-digit phone number."
                      );
                    }}
                    style={{
                      marginBottom: 1,
                      border: "none",
                      backgroundColor: "white",
                      opacity: 1,
                      textAlign: "center",
                    }}
                  />
                </div>

                <select
                  required
                  style={{
                    marginTop: 12,
                    backgroundColor: "transparent",
                    textAlign: "center",
                    border: "1px solid #DCE2E6",
                    borderRadius: 12,
                    color: "#0b0b0b",
                  }}
                  className="dropdown-menu"
                  onChange={(e) => {
                    return e.preventDefault(), setOrderedFrom(e.target.value);
                  }}
                  placeholder="Select Purchased from"
                >
                  <option value="" disabled selected>
                    Select purchased from
                  </option>
                  {prodData?.purchase_channel.map((channelName) => (
                    <option value={channelName}>{channelName}</option>
                  ))}
                </select>

                <div
                  style={{
                    display:
                      orderedFrom === "Physical Store" ? "block" : "none",
                    margin: "32px 12px",
                    background:
                      "linear-gradient(90deg, rgba(11, 11, 11, 0) 0%,rgba(11, 11, 11, 0.2) 50%,rgba(11, 11, 11, 0) 100%)",
                  }}
                >
                  <input
                    placeholder="Enter store name"
                    type="text"
                    name="storeName"
                    required={orderedFrom === "Physical Store" ? true : false}
                    style={{
                      marginBottom: 1,
                      border: "none",
                      backgroundColor: "white",
                      opacity: 1,
                      textAlign: "center",
                    }}
                  />
                </div>

                <Button
                  className="submit"
                  type=""
                  htmlType="submit"
                  value="Submit"
                  loading={loading}
                  style={{
                    position: "sticky",
                    marginBottom: "24px",
                  }}
                >
                  Register Warranty
                </Button>
              </form>
            </div>
          </center>
        </center>
      ) : (
        !productError && !mainLoader && <LandingMain />
      )}
    </>
  );
};

export { Landing };
