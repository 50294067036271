import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAKYdK8DJ2mbJUs7qC2DspuT6GeS3itMdg",
  authDomain: "dopamine-fit-prod.firebaseapp.com",
  databaseURL: "https://dopamine-fit-prod-default-rtdb.firebaseio.com",
  projectId: "dopamine-fit-prod",
  storageBucket: "dopamine-fit-prod.appspot.com",
  messagingSenderId: "580673275911",
  appId: "1:580673275911:web:f2304938d943a0f625c2a2",
  measurementId: "G-NYWP1K080B",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
