import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./OtpVerification.css";
import { useNavigate } from "react-router-dom";
import WhatsAppIcon from "../../assets/WhatsApp.svg";

import WarrantyLogo from "../../assets/WarrantyMainLogo.svg";
import { MuiOtpInput } from "mui-one-time-password-input";
import { format, subYears } from "date-fns";
import { getApi, saveApi, successMessage } from "../../helpers/ApiFuctions";

import { message, Button, Dropdown, Badge } from "antd";

const OtpVerification = () => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isDisabled, setIsDisabled] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const props = location?.state;

  const [idVerify, setIdVerify] = useState(props?.id);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  useEffect(() => {
    props?.is_redirect !== 1 && navigate("/");
  }, []);

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      setIsDisabled(false);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const otpHandler = async (e, payload = {}) => {
    e.preventDefault();
    setLoading(true);

    // const email = e.target?.email?.value;
    // const warranty_extended_to = warrantyDate;
    // const ordered_from = orderedFrom;

    const res = await saveApi(
      (payload = {
        id: idVerify,
        verify: 1,
        otp,
      }),
      "OTP Verified",
      "cubeProductsWarranty"
    );
    if (res.data.data[0]?.is_otp_matched === 1) {
      const invoiceDetails = res.data?.invoice_details[0];
      setLoading(false);
      idVerify
        ? navigate("/DownloadApp", {
            state: {
              is_redirect: 1,
              invoiceDetails,
              customerDetails: res.data.data[0],
              productName: props?.productName,
              cubecoins: props?.cubecoins,
            },
          })
        : navigate("/Landing");
      successMessage("OTP Verified");
    } else {
      message.warning({ content: "Incorrect OTP entered" });
      setLoading(false);
    }
    // }
  };

  const resendHandler = async (e, payload = {}) => {
    e.preventDefault();
    setLoading(true);
    setIsDisabled(true);
    setTimer(30);

    // const email = e.target?.email?.value;
    // const warranty_extended_to = warrantyDate;
    // const ordered_from = orderedFrom;

    const res = await saveApi(
      (payload = props?.ean_code
        ? {
            name: props?.name,
            phone: props?.phone,
            ean_code: props?.ean_code,
            is_add: 1,
            channel: props?.channel,
          }
        : {
            name: props?.name,
            phone: props?.phone,
            sku_code: props?.sku_code,
            is_add: 1,
            channel: props?.channel,
          }),
      "OTP sent",
      "cubeProductsWarranty"
    );

    console.log("res", res);
    if (res?.data.success) {
      setIdVerify(res.data.data[0]?.id);
      console.log("res", res);
      setLoading(false);

      successMessage("OTP sent");
    } else {
      message.warning({ content: "Something went wrong!" });
      setLoading(false);
    }
    // }
  };

  return (
    <center
      className="landing verification-container"
      style={{ backgroundColor: "white" }}
    >
      <div className="main-title">
        <p style={{ margin: 0 }}>Enter OTP</p>
      </div>
      <p className="small-description">
        Please enter OTP sent to +91 {props?.phone}
        <span style={{ margin: "0px 2px" }}>
          {" "}
          <img
            alt="whatsAppIcon"
            className="whatsAppIcon"
            src={WhatsAppIcon}
            width="16px"
            style={{ display: "block", margin: "auto" }}
          />{" "}
        </span>{" "}
        on WhatsApp
      </p>
      <MuiOtpInput
        inputMode="numeric"
        TextFieldsProps={{ type: "number" }}
        validateChar={(val) => !isNaN(val)}
        type="number"
        value={otp}
        onChange={handleChange}
        length={6}
        style={{
          marginTop: 54,
          marginBottom: 43,
        }}
      />
      <small style={{ color: "rgba(11, 11, 11, 0.5)" }}>
        {!isDisabled ? (
          <>
            Didn't receive the OTP? <br />
            <span
              style={{ color: "red", cursor: "pointer", marginTop: 15 }}
              onClick={resendHandler}
            >
              RESEND THE OTP
            </span>
          </>
        ) : (
          <span
            style={{ fontWeight: 600 }}
          >{`Resend the OTP in ${timer} seconds`}</span>
        )}
      </small>

      <Button
        className="verify-otp-btn"
        type=""
        loading={loading}
        disabled={otp.length < 6}
        onClick={otpHandler}
        style={{
          marginBottom: "24px",
          marginTop: "88px",
          padding: 0,
        }}
      >
        Verify
      </Button>
    </center>
  );
};

export { OtpVerification };
