import React from "react";
import { Link } from "react-router-dom";

import Footer from "../../components/footer";

const Header = React.lazy(() => import("../../components/header"));
const ismobile = window.innerWidth <= 768;

function TermsAndConditions() {
  return (
    <>
      <Header page={"terms"} />
      <div
        style={{
          padding: "2% 6%",
          width: "100%",
          maxWidth: "1500px",
          color: "#444",
          fontSize: "0.9em",
          margin: ismobile ? "15% auto auto auto" : "5% auto auto auto",
          scrollSnapAlign: "start",
          scrollMarginTop: "50px",
        }}
      >
        <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>
          Terms and Conditions for Dopamine
        </h2>
        <p>Effective date: 1st April, 2023</p>
        <p>
          Welcome to Dopamine!
          <br /> Dopamine is owned and operated by Vecino Fitness Private
          Limited ("Vecino Fitness," "we," "our," or "us"). These Terms and
          Conditions ("Terms") govern your access to and use of the Dopamine
          website, mobile application, and related services (collectively, the
          "Service"). By using the Service, you agree to be bound by these
          Terms. If you do not agree with any part of these Terms, please
          refrain from using the Service.
        </p>
        <h3>1. Acceptance of Terms</h3>
        <p>
          By accessing or using the Service, you affirm that you are of legal
          age to enter into these Terms or, if you are not of legal age, that
          you have obtained the consent of a parent or legal guardian to use the
          Service.
        </p>
        <h3>2. Privacy Policy</h3>
        <p>
          Your privacy is important to us. Please refer to our{" "}
          <Link
            to={"/privacy"}
            style={{ textDecoration: "none", color: "#ff4a01" }}
          >
            Privacy Policy
          </Link>{" "}
          to understand how we collect, use, and disclose information about you.
          By using the Service, you consent to our data practices as described
          in the Privacy Policy.
        </p>
        <h3>3. User Accounts</h3>
        <p>
          To access certain features of the Service, you may be required to
          create a user account. You are responsible for maintaining the
          confidentiality of your account credentials and for all activities
          that occur under your account. You must notify us immediately of any
          unauthorized use or suspected breach of security.
        </p>
        <h3> 4. Prohibited Activities</h3>
        <p>
          You agree not to engage in any of the following activities while using
          the Service:
        </p>
        <p>
          a. Violating any applicable laws or regulations.
          <br />
          b. Impersonating any person or entity or falsely representing your
          affiliation with any person or entity.
          <br />
          c. Interfering with or disrupting the Service or servers or networks
          connected to the Service.
          <br />
          d. Attempting to gain unauthorized access to any part of the Service
          or its related systems or networks.
          <br />
          e. Uploading, posting, or transmitting any content that is unlawful,
          harmful, defamatory, infringing, or otherwise objectionable.
          <br />
          f. Collecting or storing personal information about other users
          without their consent.
          <br />
          g. Engaging in any other conduct that, in our sole discretion, is
          deemed inappropriate or harmful.
          <br />
        </p>
        <h3>5. Intellectual Property</h3>
        <p>
          The Service and its content, including but not limited to text,
          graphics, logos, images, software, and other materials, are protected
          by copyright, trademark, and other intellectual property laws. You may
          not use, modify, reproduce, distribute, or create derivative works
          based on the Service or its content without our prior written consent.
        </p>
        <h3>6. Disclaimers</h3>
        <p>
          The Service is provided on an "as is" and "as available" basis,
          without warranties of any kind, either express or implied. We do not
          guarantee that the Service will be error-free, secure, or
          uninterrupted. Your use of the Service is at your own risk.
        </p>
        <h3>7. Limitation of Liability</h3>
        <p>
          In no event shall Dopamine, Vecino Fitness, or its affiliates be
          liable for any indirect, incidental, special, or consequential damages
          arising out of or in connection with your use of the Service, even if
          we have been advised of the possibility of such damages. Our total
          liability to you for all claims arising out of or relating to the
          Service shall not exceed the amount paid by you, if any, for accessing
          the Service.
        </p>
        <h3>8. User Content</h3>
        <p>
          You may have the opportunity to submit or post content on the Service,
          including but not limited to reviews, comments, and messages ("User
          Content"). By submitting User Content, you grant Dopamine and Vecino
          Fitness a non-exclusive, worldwide, royalty-free, perpetual,
          irrevocable, and fully sublicensable right to use, reproduce, modify,
          adapt, publish, translate, create derivative works from, distribute,
          and display such User Content in any media.
        </p>
        <h3>9. Indemnification</h3>
        <p>
          You agree to indemnify and hold Dopamine, Vecino Fitness, and its
          affiliates, directors, officers, employees, and agents harmless from
          and against any claims, liabilities, damages, losses, and expenses,
          including reasonable attorney's fees, arising out of or in connection
          with your use of the Service or your violation of these Terms.
        </p>
        <h3>10. Changes to Terms</h3>
        <p>
          We reserve the right to modify or update these Terms at any time. We
          will provide notice of significant changes, but it is your
          responsibility to review these Terms periodically for updates.
        </p>
        <h3>11. Governing Law</h3>
        <p>
           These Terms shall be governed by and construed in accordance with the
          laws of Maharashatra,India. Any dispute arising out of or relating to
          these Terms or the Service shall be subject to the exclusive
          jurisdiction of the courts of Maharashtra, India.
        </p>
        <p>
          If you have any questions or concerns regarding these Terms, please
          contact us at
        </p>
        <b>Email:</b>{" "}
        <a
          href="mailto:contact@dopamine.fit"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "#ff4a01" }}
          // className="footer-link"
        >
          contact@dopamine.fit
        </a>
        <p>
          <b> Address:</b>
          <br />
          Dopamine HQ
          <br />
          29 Floor Sunshine tower, Plot No 616, Tulsi Pipe Rd, Dadar West,
          Parel, Mumbai,
          <br /> Maharashtra 400013
        </p>
      </div>
      <Footer />
    </>
  );
}

export default React.memo(TermsAndConditions);
